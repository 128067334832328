<div class="row">
    <div class="col-12">
        <div class="card-box" *ngIf='loading'>
            <div class="responsiveLoading" >
                <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
            </div>
        </div>
        <div class="card-box" *ngIf='!loading'>
            <h4 class="m-t-0 header-title">Add Settinges</h4>

            <div class="row">
                <div class="col-12">
                    <div class="p-20">
                        <form class="form-horizontal" >
                            <div class="form-group row">
                                <div class="col-md-6" ng-app>
                                    <div class="form-group">
                                        <label class=" col-form-label">new Color</label>
                                        <input class="form-control" type="color" name="color"  [(ngModel)]="linkColor.color" (ngModelChange)="selectItem($event)">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">code<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="code" ngModel [(ngModel)]="linkColor.busniss_code">
                                    </div>
                                </div>
                                <div class="col-md-6" ng-app>
                                    <div class="form-group">
                                        <label class=" col-form-label">Min Delivery Order Amount</label>
                                        <input type="number" required
                                                class="form-control"  name="min_delivery_order_amount" ngModel [(ngModel)]="linkColor.min_delivery_order_amount">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Toggle Show Qty<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="toggle_show_qty" ngModel [(ngModel)]="linkColor.toggle_show_qty">
                                    </div>  
                                </div>
                                <div class="col-md-6" ng-app>
                                    <div class="form-group">
                                        <label class=" col-form-label">Decimal Point</label>
                                        <input type="number" required
                                                class="form-control"  name="decimal_point" ngModel [(ngModel)]="linkColor.decimal_point">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Apply Qty<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="apply_qty" ngModel [(ngModel)]="linkColor.apply_qty">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="effect">
                                        <label>Currency Id <span class="text-danger">*</span></label>
                                        <select data-placeholder="chose your job" class="custom-select mt-3" tabindex="-1" name="currency_id" ngModel [ngModel]="linkColor.currency_id">
                                            <option [ngValue]="null" select >اختر...</option>
                                            <option  *ngFor="let item of currency"  [ngValue]="item.id">{{item.name}} </option>
                                        </select>
                                    </div>
                                </div>
                                <hr>
                                
                                <div class="col-lg-12">
                                    <div class="form-group"> 
                                        <div class="custom-control custom-checkbox">
                                            
                                            <input type="checkbox" class="custom-control-input" id="customCheck2"
                                            (change)="choosdelivery($event.target.checked)" [checked]="selectedelivery">
                                            <label class="custom-control-label" for="customCheck2">توصيل </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Delivery Discount<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="delivery_discount" ngModel [(ngModel)]="linkColor.delivery_discount">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Min Delivery Discount Cost<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="min_delivery_discount_cost" ngModel [(ngModel)]="linkColor.min_delivery_discount_cost">
                                    </div>
                                </div>
                                <hr>


                                <div class="col-lg-12">
                                   <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                                
                                            <input type="checkbox" class="custom-control-input" id="customCheck3"
                                            (change)="choosecollection($event.target.checked)" [checked]="selectedcollection">
                                            <label class="custom-control-label" for="customCheck3">سفري</label>
                                        </div>
                                   </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Collection Discount<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="collection_discount" ngModel [(ngModel)]="linkColor.collection_discount">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Min Collection Discount Cost<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="min_collection_discount_cost" ngModel [(ngModel)]="linkColor.min_collection_discount_cost">
                                    </div>
                                </div>
                                <hr>


                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            
                                            <input type="checkbox" class="custom-control-input" id="customCheck4"
                                            (change)="chooseplace($event.target.checked)" [checked]="selectedplace">
                                            <label class="custom-control-label" for="customCheck4">صالة</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Place Discount<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="place_discount" ngModel [(ngModel)]="linkColor.place_discount">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Min Place Discount Cost<span class="text-danger">*</span></label>
                                        <input type="number" required
                                                class="form-control"  name="min_place_discount_cost" ngModel [(ngModel)]="linkColor.min_place_discount_cost">
                                    </div>
                                </div>
                                <hr>


                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="userName">printer <span class="text-danger">*</span></label>
                                        <div class="custom-control custom-checkbox">
                                            
                                            <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            (change)="chooseItem($event.target.checked)" [checked]="selectedItem">
                                            <label class="custom-control-label" for="customCheck1">using printer</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12" >
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                                
                            </div>
                            
                            
                            <div class="col-lg-12">
                                <button style="float: right;" type="button" [disabled]="loadingButtonColor" class="btn btn-info waves-effect waves-light" (click)="AddColorSUbmit()">edit Settinges</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <!-- end row -->

        </div> <!-- end card-box -->
    </div><!-- end col -->
</div>